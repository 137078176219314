import { default as abonnement_45geactiveerdb4Oj0Ol8mjMeta } from "/home/cleavr/versvandevelde.nl/releases/20250303064113556/pages/abonnement-geactiveerd.vue?macro=true";
import { default as abonnement_45geannuleerdOcGrBeAfpeMeta } from "/home/cleavr/versvandevelde.nl/releases/20250303064113556/pages/abonnement-geannuleerd.vue?macro=true";
import { default as abonnement_45samenstellenMbJSWlfse3Meta } from "/home/cleavr/versvandevelde.nl/releases/20250303064113556/pages/abonnement-samenstellen.vue?macro=true";
import { default as abonnement962t9hK3shMeta } from "/home/cleavr/versvandevelde.nl/releases/20250303064113556/pages/abonnement.vue?macro=true";
import { default as abonnementHDTBrvmyX0Meta } from "/home/cleavr/versvandevelde.nl/releases/20250303064113556/pages/account/abonnement.vue?macro=true";
import { default as adresGftt0dIiVSMeta } from "/home/cleavr/versvandevelde.nl/releases/20250303064113556/pages/account/adres.vue?macro=true";
import { default as gegevens4W5RZuWZ7ZMeta } from "/home/cleavr/versvandevelde.nl/releases/20250303064113556/pages/account/gegevens.vue?macro=true";
import { default as indexor4jDuzAxgMeta } from "/home/cleavr/versvandevelde.nl/releases/20250303064113556/pages/account/index.vue?macro=true";
import { default as resetwachtwoordbkwnCnlLapMeta } from "/home/cleavr/versvandevelde.nl/releases/20250303064113556/pages/account/resetwachtwoord.vue?macro=true";
import { default as wachtwoord_45wijzigenxdSm92pmboMeta } from "/home/cleavr/versvandevelde.nl/releases/20250303064113556/pages/account/wachtwoord-wijzigen.vue?macro=true";
import { default as activeren06bfG0vemJMeta } from "/home/cleavr/versvandevelde.nl/releases/20250303064113556/pages/activeren.vue?macro=true";
import { default as afrekenen92oCqVCdk6Meta } from "/home/cleavr/versvandevelde.nl/releases/20250303064113556/pages/afrekenen.vue?macro=true";
import { default as algemenevoorwaardenv4iMWOpzzoMeta } from "/home/cleavr/versvandevelde.nl/releases/20250303064113556/pages/algemenevoorwaarden.vue?macro=true";
import { default as contact8BxjIvKJoXMeta } from "/home/cleavr/versvandevelde.nl/releases/20250303064113556/pages/contact.vue?macro=true";
import { default as indexPOToex5hUOMeta } from "/home/cleavr/versvandevelde.nl/releases/20250303064113556/pages/index.vue?macro=true";
import { default as inloggenWrpDQktksgMeta } from "/home/cleavr/versvandevelde.nl/releases/20250303064113556/pages/inloggen.vue?macro=true";
import { default as overonsjZxGi34BzxMeta } from "/home/cleavr/versvandevelde.nl/releases/20250303064113556/pages/overons.vue?macro=true";
import { default as registrerenpokob3T8zAMeta } from "/home/cleavr/versvandevelde.nl/releases/20250303064113556/pages/registreren.vue?macro=true";
export default [
  {
    name: "abonnement-geactiveerd",
    path: "/abonnement-geactiveerd",
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20250303064113556/pages/abonnement-geactiveerd.vue").then(m => m.default || m)
  },
  {
    name: "abonnement-geannuleerd",
    path: "/abonnement-geannuleerd",
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20250303064113556/pages/abonnement-geannuleerd.vue").then(m => m.default || m)
  },
  {
    name: "abonnement-samenstellen",
    path: "/abonnement-samenstellen",
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20250303064113556/pages/abonnement-samenstellen.vue").then(m => m.default || m)
  },
  {
    name: "abonnement",
    path: "/abonnement",
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20250303064113556/pages/abonnement.vue").then(m => m.default || m)
  },
  {
    name: "account-abonnement",
    path: "/account/abonnement",
    meta: abonnementHDTBrvmyX0Meta || {},
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20250303064113556/pages/account/abonnement.vue").then(m => m.default || m)
  },
  {
    name: "account-adres",
    path: "/account/adres",
    meta: adresGftt0dIiVSMeta || {},
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20250303064113556/pages/account/adres.vue").then(m => m.default || m)
  },
  {
    name: "account-gegevens",
    path: "/account/gegevens",
    meta: gegevens4W5RZuWZ7ZMeta || {},
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20250303064113556/pages/account/gegevens.vue").then(m => m.default || m)
  },
  {
    name: "account",
    path: "/account",
    meta: indexor4jDuzAxgMeta || {},
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20250303064113556/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-resetwachtwoord",
    path: "/account/resetwachtwoord",
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20250303064113556/pages/account/resetwachtwoord.vue").then(m => m.default || m)
  },
  {
    name: "account-wachtwoord-wijzigen",
    path: "/account/wachtwoord-wijzigen",
    meta: wachtwoord_45wijzigenxdSm92pmboMeta || {},
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20250303064113556/pages/account/wachtwoord-wijzigen.vue").then(m => m.default || m)
  },
  {
    name: "activeren",
    path: "/activeren",
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20250303064113556/pages/activeren.vue").then(m => m.default || m)
  },
  {
    name: "afrekenen",
    path: "/afrekenen",
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20250303064113556/pages/afrekenen.vue").then(m => m.default || m)
  },
  {
    name: "algemenevoorwaarden",
    path: "/algemenevoorwaarden",
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20250303064113556/pages/algemenevoorwaarden.vue").then(m => m.default || m)
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20250303064113556/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20250303064113556/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "inloggen",
    path: "/inloggen",
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20250303064113556/pages/inloggen.vue").then(m => m.default || m)
  },
  {
    name: "overons",
    path: "/overons",
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20250303064113556/pages/overons.vue").then(m => m.default || m)
  },
  {
    name: "registreren",
    path: "/registreren",
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20250303064113556/pages/registreren.vue").then(m => m.default || m)
  }
]